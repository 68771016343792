@use "scrollbars";
@use "forms";
@use "icons";
@use "node_modules/@ag-grid-community/styles" as ag;
@include ag.grid-styles();

// This should be uncommented when we're ready to use the font
// @use "../nqs-icon-font";

app-query-nav,
app-field-nav {
    display: flex;
    flex-direction: column;
    flex: 0 0 350px;
    padding: 2rem 2rem 4rem;
    height: 100%;
}

main {
    display: flex;
    align-items: flex-start;
    height: 100%;
    overflow: hidden;
}

aside {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
}

section {
    flex: 1 1 auto;
    border-left: 1px solid var(--layout-border);
    padding: 2rem;
    height: 100%;
    overflow-y: auto;
}

.query-manager,
.field-builder {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.create-new {
    position: absolute;
    right: -1rem;
    top: -0.2rem;
}
