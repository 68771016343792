::-webkit-input-placeholder {
    padding-left: 5px;
}

::-moz-placeholder {
    padding-left: 5px;
}

:-ms-input-placeholder {
    padding-left: 5px;
}

:-moz-placeholder {
    padding-left: 5px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px transparent;
    border-radius: 10px;
    background: transparent;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
    background: var(--layout-border);
}

::-moz-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
    border-radius: 10px;
    background: transparent;
}

::-moz-scrollbar {
    width: 8px;
    height: 8px;
}

::-moz-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
}

::-ms-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
    border-radius: 10px;
    background: transparent;
}

::-ms-scrollbar {
    width: 8px;
    height: 8px;
}

::-ms-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
}

.ag-root {
    ::-ms-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-moz-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background: transparent;
    }
}
