svg[class^="operator-icon"] {
    width: 20px;
    height: 20px;
    display: inline-block;

    line {
        fill: none;
        stroke: var(--text-highlight);
        stroke-width: 2;
        stroke-miterlimit: 10;
    }
}
