.input-label,
.toggle-label {
    font-size: 1rem;
    font-weight: 700;
    font-family: NewsGothicBTDemi;
    display: block;
    margin-top: 0.454545rem;
    margin-bottom: 0.454545rem;
    text-transform: uppercase;
    color: var(--input-label-text);
}
